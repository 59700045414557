<template>
    <section class="xbts-portfolio">
        <table class="table table-striped table-borderless">
            <thead>
            <tr>
                <th>{{$t('assets.asset')}}</th>
                <th>{{$t('wallet.available')}}</th>
                <th>{{$t('wallet.order')}}</th>
                <th>{{$t('wallet.action')}}</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="item in balances" v-bind:key="item.symbol" v-show="item.amount > 0 || item.limit_orders > 0">
                    <td>
                        <router-link :to="'/asset/' + item.symbol">
                            <img v-show="assets[item.symbol]" :src="'images/assets/' + item.symbol.replace('XBTSX.', '').toLowerCase() + '.png'" class="mr-1 icon-18"/> {{item.symbol.replace('XBTSX.', '')}}
                        </router-link>

                    </td>
                    <td>
                        {{item.real}}
                    </td>
                    <td>
                        {{(item.limit_orders / 10 ** item.precision).toFixed(item.precision)}}
                    </td>
                    <td>

                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
import eventBus from '@/plugins/event-bus';
import {nativeAssets, xbtsAssets, xbtsFiat, otherAssets, defiAssets} from '@/config';

export default {
    name: "Portfolio",
    data() {
        return {
            account: null,
            assets: {},
            items: [],
        }
    },
    computed: {
      balances() {
          return this.$store.getters['accounts/portfolio'];
      }
    },
    methods: {

    },
    async created() {
        const allAssets = [].concat(nativeAssets, xbtsAssets, xbtsFiat, otherAssets, defiAssets);
        for (let i=0; i < allAssets.length; i++) {
            this.assets[allAssets[i]] = true;
        }
        await this.$store.dispatch('accounts/updateBalances', this.$route.params.account);
        eventBus.on('account:portfolio', async (data) => {
        });
    },
}
</script>

<style scoped>

</style>
