<template>
  <div>
    <PaperWallet/>
  </div>
</template>

<script>
import PaperWallet from '@/components/wallet/PaperWallet';

export default {
  name: "TabKeys",
  components: {
    PaperWallet,
  }
}
</script>

<style scoped>

</style>