<template>
    <section class="xbts-activity">
        <table class="table table-striped table-borderless" v-if="assets && account">
            <thead>
            <tr>
                <th>id</th>
                <th>type</th>
                <th>fee</th>
                <th>info</th>
                <th>time</th>
            </tr>
            </thead>
            <tbody v-if="account.history.length">
            <tr v-bind:key="item.id" v-for="item in account.history">
                <td><a :href="explorer + '/block/' + item.block_num + '/' + item.trx_in_block" target="_blank">{{item.id}}</a>
                </td>
                <td>
                    <b-badge pill :variant="colors[item.op[0]] ? 'outline-' + colors[item.op[0]] : 'outline-info'" size="lg" class="text-uppercase">
                        {{$t('ops.'+ops[item.op[0]])}}
                    </b-badge>
                </td>
                <td>{{item.op[1].fee.amount / 10 ** assets[item.op[1].fee.asset_id].precision}} {{assets[item.op[1].fee.asset_id].symbol}}</td>
                <td></td>
                <td></td>
            </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
import {opById, network} from '@/config';

export default {
    name: "Activity",
    data() {
        return {
            ops: opById,
            explorer: network.EXPLORER,
            account: null,
            colors: {
                "0": "success",
                "1": "warning",
                "2": "danger",
                "4": "success"
            }
        }
    },
    watch: {
        '$route.params.account': {
            handler: async function (account) {
                this.account = await this.$store.dispatch('accounts/getAccount', account);
            },
            deep: true,
            immediate: true
        },
    },
    async created() {
        if (!this.account) {
            this.account = await this.$store.dispatch('accounts/getAccount', this.$route.params.account);
        }
    },
    computed: {
        assets() {
            return this.$store.getters['accounts/portfolio'];
        }
    },
}
</script>

<style scoped>

</style>
