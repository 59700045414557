<template>
  <section class="xbts-account">
    <div class="card">
      <div class="card-body">
        <p class="text-uppercase">
          <router-link to="/">XBTS</router-link>
          / {{ this.$route.params.account }} {{$root.account.account.id}}
        </p>

        <hr/>
        <div class="">
          <b-button @click="tab = 'portfolio'" :variant="tab === 'portfolio' ? 'primary' : 'secondary'" size="sm">
            Portfolio
          </b-button>
          <b-button @click="tab = 'activity'" :variant="tab === 'activity' ? 'primary' : 'secondary'" size="sm"
                    class="ml-2">Activity
          </b-button>
          <b-button :class="tab ==='keys' ? 'btn-primary' : 'btn-secondary'" @click="showKeys" class="btn btn-sm ml-2"
                    type="button">Private
          </b-button>
        </div>
        <hr/>
        <div v-show="tab === 'portfolio'">
          <Portfolio/>
        </div>
        <div v-show="tab === 'activity'">
          <Activity/>
        </div>
        <div v-show="tab === 'keys'">
          <TabKeys/>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import eventBus from '@/plugins/event-bus';
import Portfolio from '@/components/account/Portfolio';
import Activity from '@/components/account/Activity';
import TabKeys from '@/components/wallet/TabKeys';

export default {
  name: "Account",
  components: {
    Portfolio,
    Activity,
    TabKeys,
  },
  data() {
    return {
      tab: 'portfolio'
    }
  },
  methods: {
    async showKeys() {
      this.tab = 'keys';
      eventBus.emit('wallet:print', this.$root.account)
    },
  },
  async created() {
    if (this.$route.params.account) {
      //await eventBus.emit('account:portfolio', this.$route.params.account);

    }

  },
  computed: {
    viewAccount() {
      return this.$store.getters['accounts/viewAccount'];
    },
  },
}
</script>

<style scoped>

</style>
